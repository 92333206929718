import './scss/fonts.scss';
import './scss/app.scss';
import './scss/overwrites.scss';
import './scss/custom.scss';
import './scss/utils.scss';
import './scss/main.scss';
import './scss/main-responsive.scss';

require('bootstrap/js/src/tab');

import Plyr from 'plyr';

import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';

import Swiper, {EffectFade, Navigation, Pagination, Controller, Autoplay} from 'swiper/core';
import {TimelineMax, Expo, Linear, Elastic, CSSPlugin, gsap} from 'gsap';

Swiper.use([Navigation, Pagination, EffectFade, Controller, Autoplay]);

gsap.registerPlugin(CSSPlugin);

// Mobile menu
(function () {
    const toggler = document.querySelector('.navbar-menu-toggler'),
        menu = document.querySelector('.navbar-menu'),
        body = document.body,
        logoWhite = document.getElementById('logo-white'),
        logo = document.getElementById('logo'),

    navbarInv = document.getElementById('navbar-invert');

    let a, b;
    logoWhite.classList.add('transparent');
    logoWhite.style.display = 'none';
    logo.style.display = 'flex';
    function toggleMenu() {
        var that = this;

        clearTimeout(b);

        if (!menu.classList.contains('menu-open')) {
            logoWhite.style.display = 'flex';
            if(navbarInv){
                if(navbarInv.classList.contains('navbar-invert')){
                    body.classList.add('menu-is-open');
                    logoWhite.classList.remove('transparent');
                    logo.style.display = 'none';
                    menu.style.display = 'flex';
                    setTimeout(function () {
                        menu.classList.add('menu-open');
                    }, 50);
                }
            }
            else{
                body.classList.add('menu-is-open');
                logoWhite.classList.remove('transparent');
                logo.style.display = 'none';
                logoWhite.style.display = 'flex';
                menu.style.display = 'flex';
                setTimeout(function () {
                    menu.classList.add('menu-open');
                }, 50);
            }
        } else {
            if(navbarInv){
                if(navbarInv.classList.contains('navbar-invert')){
                    logoWhite.classList.add('transparent');
                    body.classList.remove('menu-is-open');
                    menu.classList.remove('menu-open');
                    logoWhite.style.display = 'none';
                    logo.style.display = 'flex';
                    // setTimeout(function () {
                    //
                    // }, 500);
                    b = setTimeout(function () {
                        menu.style.display = '';
                    }, 650);
                }
            }else{
                logoWhite.classList.add('transparent');
                body.classList.remove('menu-is-open');
                menu.classList.remove('menu-open');

                setTimeout(function () {
                    logoWhite.style.display = 'none';
                }, 500);
                setTimeout(function () {
                    logo.style.display = 'flex';
                }, 500);
                b = setTimeout(function () {
                    menu.style.display = '';
                }, 650);
            }
        }
    }

    if (toggler) {
        toggler.addEventListener('click', toggleMenu, false);
    }
})();

(function () {
    let opened = null
    const toggleVisibility = e => e.classList.toggle('custom-dropdown-menu-open')

    const handleDropdown = e => {

        const clickedItem = e.parentElement.children[0].nextElementSibling

        toggleVisibility(clickedItem)

        if (!opened) {
            opened = clickedItem
        } else if (opened == clickedItem) {
            opened = null
        } else {
            toggleVisibility(opened)
            opened = clickedItem
        }

    }

    const handleClick = e => {

        updateFilter(e.target);

        if (e.target.matches('.form-control')) {
            if (e.target.parentNode.dataset.toggle === 'custom-dropdown') {
                e.preventDefault();

                handleDropdown(e.target.parentNode)
            } else if (opened && !e.target.parentNode.matches('.custom-dropdown-menu') && !e.target.parentNode.matches('.custom-dropdown-menu > *')) {
                toggleVisibility(opened)
                opened = null
            }
        } else {
            if (e.target.dataset.toggle === 'custom-dropdown') {
                e.preventDefault();

                handleDropdown(e.target)
            } else if (opened && !e.target.matches('.custom-dropdown-menu') && !e.target.matches('.custom-dropdown-menu > *')) {
                toggleVisibility(opened)
                opened = null
            }
        }

    }

    const updateFilter = e => {

        if (e.classList.contains('dropdown-filter-option')) {
            opened.previousElementSibling.innerHTML = `<span>${e.parentNode.parentNode.parentNode.parentNode.children[0].children[0].innerText}</span>${e.innerText}`;
        }

    }

    document.addEventListener('click', handleClick)
})();

// Accordion menu
(function () {
    let opened = null

    let a;

    const toggler = document.querySelectorAll('[data-accordion]')

    const toggleVisibility = e => {
        if (window.innerWidth <= 768) {
            e.classList.toggle('accordion-open')
            e.previousElementSibling.classList.toggle('accordion-is-open')

            clearTimeout(a);

            if (e.classList.contains('accordion-open')) {
                e.style.display = 'block'
                e.style.maxHeight = e.scrollHeight + 'px';
            } else {
                e.style.maxHeight = '';
                a = setTimeout(function () {
                    e.style.display = ''
                }, 600);
            }
        }
    }

    const checkIfOpened = e => {
        if (e.classList.contains('accordion-open')) {
            e.style.display = 'block'
            e.style.maxHeight = e.scrollHeight + 'px';
        }
    }

    toggler.forEach(el => checkIfOpened(el.nextElementSibling))

    const handleAccordion = e => {

        const clickedItem = e.parentElement.children[0].nextElementSibling

        toggleVisibility(clickedItem)

        if (!opened) {
            opened = clickedItem
        } else if (opened == clickedItem) {
            opened = null
        } else {
            toggleVisibility(opened)
            opened = clickedItem
        }

    }

    const handleClick = e => {

        if (e.target.dataset.accordion) {
            e.preventDefault();

            handleAccordion(e.target)
        }

    }

    document.addEventListener('click', handleClick)
})();

// Global Navigation
$(function () {
    var trigger = $('[data-trigger="dropdown"]'),
        dropdownRoot = $('.dropdown-root'),
        dropdownBackground = $('.dropdown-background'),
        dropdownContainer = $('.dropdown-container'),
        dropdownSection = $('.dropdown-section'),
        body = $('body');

    var a, b, c, i = 0, target;

    function showNavigation(e) {
        target = $('.dropdown-section[data-dropdown="' + $(e.target).data('dropdown') + '"]');

        if (trigger.is(e.target)) {

            if ($(e.target).data('dropdown') === 'solutions') {
                dropdownBackground.css({
                    background: 'linear-gradient(to right, white 0%, white 60%, #f0f4f5 60%, #f0f4f5 100%)',
                });
            } else {
                dropdownBackground.css({
                    background: '',
                });
            }

            dropdownRoot.css({
                width: target.css('width'),
            });

            dropdownSection.removeClass('active');

            dropdownSection.css({
                opacity: '',
            });

            dropdownRoot.show();

            setTimeout(function () {
                dropdownRoot.css({
                    transform: 'translate3d(' + ($(e.target).offset().left - (parseInt(target.css('width')) / 2) + ($(e.target).innerWidth() / 2)) + 'px, 0, 0)',
                    transition: i <= 0 ? 'none' : '',
                });

                i++;

                dropdownBackground.css({
                    height: target.innerHeight() + 'px',
                    transform: 'none',
                    opacity: '1',
                });

                dropdownContainer.css({
                    height: target.innerHeight() + 'px',
                });

                target.css({
                    opacity: '1',
                    transform: 'none',
                });

                target.addClass('active');
            }, 50);
        } else {
            clearTimeout(a);
            clearTimeout(b);
        }
    }
    document.querySelectorAll('ul.navbar-nav li').forEach(element => element.addEventListener('click', () => {
        const body = document.querySelector('.has-mobile-navigation');
        const menu = document.querySelector('.navbar-menu'),
            logoWhite = document.getElementById('logo-white'),
            logo = document.getElementById('logo');
        if(body){
            menu.classList.remove('menu-open');
            menu.style.display = 'none';
            body.classList.remove('menu-is-open');
            logoWhite.style.display = 'none';
            // setTimeout(function () {
            //     logoWhite.style.display = 'none';
            // }, 500);
            logo.style.display = 'flex';
            // setTimeout(function () {
            //     logo.style.display = 'flex';
            // }, 500);
        }
        menu.style.display = 'block';
    }));
    function hideNavigation(e) {
        a = setTimeout(function () {
            dropdownSection.css({
                opacity: '',
                transform: '',
            });

            dropdownSection.removeClass('active');

            dropdownBackground.css({
                transform: '',
                opacity: ''
            });

            b = setTimeout(function () {
                dropdownRoot.hide();
                dropdownRoot.css({
                    width: '',
                });
                dropdownBackground.css({
                    background: '',
                });
            }, 250);
        }, 200);
    }

    function initMenu() {
        if ($(window).width() < 768) {
            if (!body.hasClass('has-mobile-navigation')) {
                body.addClass('has-mobile-navigation');

                body.off('mouseover');

                body.on('click', function (e) {
                    clearTimeout(a);
                    clearTimeout(b);

                    if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                        target = $(e.target).parent().find(mobileNavigation);

                        e.preventDefault();

                        if (!target.hasClass('mobile-sub-navigation-open')) {
                            $(e.target).addClass('active');
                            target.addClass('mobile-sub-navigation-open');
                        } else {
                            $(e.target).removeClass('active');
                            target.removeClass('mobile-sub-navigation-open');
                        }
                    } else {
                        $(e.target).removeClass('active');
                    }
                });
            }
        } else {
            body.removeClass('has-mobile-navigation');

            body.off('click');

            body.on('click', function (e) {
                clearTimeout(a);
                clearTimeout(b);

                if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
                    return true;
                }
            });

            body.on('mouseover', function (e) {
                clearTimeout(a);
                clearTimeout(b);

                if (trigger.is(e.target) || trigger.has(e.target).length > 0 || dropdownSection.is(e.target) || dropdownSection.has(e.target).length > 0) {
                    showNavigation(e);
                } else {
                    hideNavigation(e);
                }
            });
        }
    }

    initMenu();
    $(window).on('resize', initMenu);
});

// Reveal on scroll
(function() {
    const options = {
        threshold: 0.1,
        rootMargin: '0px 0px 0px 0px',
    };

    var elementsArr = [
        'header',
        'footer',
        '.hero-content',
        '.hero-content-text',
        '.hero-content-media',
        '.hero-content-text h1',
        '.hero-content-text p',
        '.hero-content-text a',
        '.media-grid img',
        // '.who-we-serve-content h6',
        '.who-we-serve-content h2',
        '.who-we-serve-content p',
        '.section-help',
        '.help-by h2',
        '.help-by ul li',
        '.root h2',
        '.contact-form',
        '.contact-form label',
        '.contact-form input',
        '.contact-form textarea',
        '.feature div',
        '.feature div p',
    ]

    let itemDelay = .1;

    function onEntry(entry) {

        let itemLoad = 0;

        entry.forEach((change, i) => {

            if (change.isIntersecting) {

                itemLoad++;

                new TimelineMax({})
                    .to(change.target, {
                        opacity: 1,
                        translateY: 0,
                        ease: Expo.easeOut,
                        duration: 2,
                        // delay: i - i * 0.96,
                        delay: itemLoad * itemDelay,
                    }).call(function() {
                    observer.unobserve(change.target);

                    change.target.style.transform = '';
                    change.target.style.opacity = '';
                    change.target.style.willChange = '';
                });

            }
        });
    }

    // instantiate a new Intersection Observer
    let observer = new IntersectionObserver(onEntry, options);

    // list of cards
    let elements = document.querySelectorAll(elementsArr);

    const setProperties = e => {
        e.style.transform = 'translate3d(0, 2rem, 0)';
        e.style.opacity = '0';
        e.style.willChange = 'transform, opacity';
    }

    elements.forEach(elm => setProperties(elm))

    // loop through all elements
    // pass each element to observe method
    for (let elm of elements) {
        observer.observe(elm);
    }
})();


function handleAnchorLinkClick(event) {
    const href = event.currentTarget.getAttribute("href");
    const linkElements = document.querySelectorAll('a[href*="#"]');

    if (linkElements) {
        setTimeout(function() {
            hideAnchorId();
            // Your other code to handle the link click goes here
        }, 0);
    }
}

function hideAnchorId() {
    const currentUrl = window.location.href;
    const index = currentUrl.indexOf("#");

    if (index !== -1) {
        const newUrl = currentUrl.slice(0, index);
        window.history.replaceState(null, null, newUrl);
    }
}

// Add event listener to all anchor links
const anchorLinks = document.querySelectorAll('a[href*="#"]');
anchorLinks.forEach(function(link) {
    link.addEventListener("click", handleAnchorLinkClick);
});


function onSubmit(token) {
    document.getElementById("submit").submit();
}
window.onSubmit = onSubmit;